import Heading, { HeadingLevels } from '@/components/atoms/legacy/Heading';
import Badge, { BadgeVariants } from '@/components/molecules/Badge';
import ClampedText from '@/components/molecules/ClampedText';
import { DynamicImageWrapper } from '@/components/molecules/DynamicImageWrapper/DynamicImageWrapper';
import {
  CHOOSE_MODEL,
  MSRP,
  PRICE_COMING_SOON,
  STARTING,
} from '@/lib/constants';
import { formatNumberWithCommas } from '@/lib/formatNumberWithCommas';
import { priceFormatter } from '@/lib/priceFormatter';
import { GetCustomOrderModelsForMakeResponse } from '@/lib/schema/models/types';

export interface EVOptionProps {
  ModelId: string;
  Model: string;
  MainImage: string;
  MSRP: number;
}

export interface EVCompareModelSelectionProps {
  makeEVOptions: GetCustomOrderModelsForMakeResponse;
  selectedYear: string;
  activeModelIndex: number;
  yearList: string[];
  handleBadgeClick: (year: string) => void;
  handleModelClick: (model: string, imageUrl: string, index: number) => void;
  showComparisonDetailPage?: boolean;
  selectedEditEVIndex?: number;
  selectedMake: string;
}

export const EVCompareModelSelection: React.FC<
  EVCompareModelSelectionProps
> = ({
  makeEVOptions,
  selectedYear,
  activeModelIndex,
  yearList,
  handleBadgeClick,
  handleModelClick,
  showComparisonDetailPage,
  selectedEditEVIndex,
  selectedMake,
}) => {
  return (
    <>
      <Heading
        className="mb-m ml-s text-[clamp(1rem,1vw,1.25rem)] l:mb-xl"
        level={HeadingLevels.H4}
      >
        {CHOOSE_MODEL}
      </Heading>
      <section>
        {yearList.map((year) => (
          <Badge
            key={year}
            label={year}
            className="mb-m mr-s cursor-pointer text-microMedium"
            onClick={() => handleBadgeClick(year)}
            variant={
              selectedYear.toString() === year
                ? BadgeVariants.Blue
                : BadgeVariants.Gray
            }
          />
        ))}
        <section className="grid grid-cols-2 overflow-scroll pb-[60px] l:grid-cols-4">
          {makeEVOptions[Number(selectedYear)]?.map((model, index) => (
            <div
              className={`align-center rounded-xs m-xs flex cursor-pointer flex-col justify-center border-[1px] border-solid pb-m ${
                index === activeModelIndex
                  ? 'border-brandElectric bg-green-light'
                  : 'border-neutral-300'
              }`}
              key={model.ModelId}
              onClick={() =>
                handleModelClick(model.Model, model?.MainImage ?? '', index)
              }
            >
              <DynamicImageWrapper
                make={selectedMake}
                className={`w-full rounded-t-lg !object-cover ${
                  showComparisonDetailPage ||
                  !model.MainImage ||
                  selectedEditEVIndex === 1
                    ? ''
                    : '-scale-x-100'
                }`}
                src={model?.MainImage ?? undefined}
                alt="model_image"
                priority={true}
                ratio={0.7}
              />
              <div className="px-m pt-m">
                <p className="text-microMedium">{model.Model}</p>
                <ClampedText className="text-microLight">
                  {model.MSRP === 0
                    ? priceFormatter(model.MSRP, PRICE_COMING_SOON)
                    : `${STARTING} ${MSRP} $${formatNumberWithCommas(
                        model.MSRP
                      )}`}
                </ClampedText>
              </div>
            </div>
          ))}
        </section>
      </section>
    </>
  );
};
