import Image from '@/components/atoms/Image';
import Heading, { HeadingLevels } from '@/components/atoms/legacy/Heading';
import ClampedText from '@/components/molecules/ClampedText';
import { FIRST_EV, MSRP, SECOND_EV, STARTING } from '@/lib/constants';
import { EVDetailProps } from '@/types/compare-ev';
import { CarToLeftIcon, CarToRightIcon } from '../../atoms/icons';

interface EVCompareModelHeaderProps {
  modelHeader: string;
  selectedFirstEVStyle?: string;
  selectedSecondEVStyle?: string;
  firstEVIconStyle?: string;
  secondEVIconStyle?: string;
  showComparisonDetailPage?: boolean;
  selectedFirstEVDetails: EVDetailProps;
  selectedSecondEVDetails: EVDetailProps;
}

export const EVCompareModelHeader: React.FC<EVCompareModelHeaderProps> = ({
  modelHeader,
  selectedFirstEVStyle = '',
  firstEVIconStyle = '',
  selectedSecondEVStyle = '',
  secondEVIconStyle = '',
  selectedFirstEVDetails,
  selectedSecondEVDetails,
}) => {
  const vehicleDetailsList = [
    {
      selectedEV: selectedFirstEVDetails,
      selectedEVStyle: selectedFirstEVStyle,
      selectedIconStyle: firstEVIconStyle,
    },
    {
      selectedEV: selectedSecondEVDetails,
      selectedEVStyle: selectedSecondEVStyle,
      selectedIconStyle: secondEVIconStyle,
    },
  ];
  return (
    <>
      <Heading
        className="mb-m mt-[12px] text-[clamp(1.25rem,1.5vw,1.75rem)] l:mb-xl"
        level={HeadingLevels.H4}
      >
        {modelHeader}
      </Heading>
      <section className="mb-[20px] hidden w-full justify-between m:flex">
        {vehicleDetailsList.map((vehicleDetail, index) => {
          const { selectedEV, selectedEVStyle, selectedIconStyle } =
            vehicleDetail;
          return (
            <div
              className={`${
                selectedEV.trim ? '!border-solid' : ''
              } ${selectedEVStyle}`}
              key={index}
            >
              {selectedEV.trim ? (
                <div className="fadeIn flex h-full w-full transform justify-between px-s">
                  {selectedEV.imageURL && (
                    <Image
                      src={selectedEV.imageURL}
                      alt="model_image"
                      className={`!relative ${index === 0 ? '-scale-x-100' : ''} object-contain`}
                      dynamicClassName="flex"
                      width={150}
                      height={150}
                    />
                  )}
                  <div className="align-start flex flex-col justify-center">
                    <ClampedText className="whitespace-nowrap text-microNormal text-neutral-600">
                      {selectedEV.make}
                    </ClampedText>
                    <p className="text-microNormal">{selectedEV.model}</p>
                    <ClampedText className="overflow-ellipsis whitespace-nowrap text-microNormal text-neutral-600">
                      {selectedEV.trim}
                    </ClampedText>
                    <ClampedText className="whitespace-nowrap text-microNormal text-neutral-600">
                      {STARTING} {MSRP} ${selectedEV.MSRP.toLocaleString()}
                    </ClampedText>
                  </div>
                </div>
              ) : (
                <>
                  <div className={selectedIconStyle}>
                    {index === 0 ? (
                      <CarToRightIcon className="size-[20px]" />
                    ) : (
                      <CarToLeftIcon className="size-[20px]" />
                    )}
                  </div>
                  <span className={`text-[clamp(0.6rem,1vw,0.75rem)]`}>
                    {index === 0 ? FIRST_EV : SECOND_EV}
                  </span>
                </>
              )}
            </div>
          );
        })}
      </section>
    </>
  );
};
